/* tslint:disable */
/* eslint-disable */
/* @relayHash 90ce07b67a4d671c76549ad0cdfe18a3 */

import { ConcreteRequest } from "relay-runtime";
export type RefreshDisposableEmailDomainsInput = {
    clientMutationId: string;
};
export type RefreshDisposableEmailDomainsMutationVariables = {
    input: RefreshDisposableEmailDomainsInput;
};
export type RefreshDisposableEmailDomainsMutationResponse = {
    readonly refreshDisposableEmailDomains: {
        readonly clientMutationId: string;
    };
};
export type RefreshDisposableEmailDomainsMutation = {
    readonly response: RefreshDisposableEmailDomainsMutationResponse;
    readonly variables: RefreshDisposableEmailDomainsMutationVariables;
};



/*
mutation RefreshDisposableEmailDomainsMutation(
  $input: RefreshDisposableEmailDomainsInput!
) {
  refreshDisposableEmailDomains(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "RefreshDisposableEmailDomainsPayload",
            "kind": "LinkedField",
            "name": "refreshDisposableEmailDomains",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RefreshDisposableEmailDomainsMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RefreshDisposableEmailDomainsMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": "90ce07b67a4d671c76549ad0cdfe18a3",
            "metadata": {},
            "name": "RefreshDisposableEmailDomainsMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'fca8c0853f686fdb0fd4df14e5e0dbf4';
export default node;
