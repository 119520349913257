/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PremoderateEmailAddressConfig_formValues = {
    readonly premoderateEmailAddress: {
        readonly tooManyPeriods: {
            readonly enabled: boolean;
        } | null;
        readonly emailAliases: {
            readonly enabled: boolean;
        } | null;
    } | null;
    readonly " $refType": "PremoderateEmailAddressConfig_formValues";
};
export type PremoderateEmailAddressConfig_formValues$data = PremoderateEmailAddressConfig_formValues;
export type PremoderateEmailAddressConfig_formValues$key = {
    readonly " $data"?: PremoderateEmailAddressConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"PremoderateEmailAddressConfig_formValues">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enabled",
            "storageKey": null
        } as any)
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "PremoderateEmailAddressConfig_formValues",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "PremoderateEmailAddressConfiguration",
                "kind": "LinkedField",
                "name": "premoderateEmailAddress",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "TooManyPeriodsConfig",
                        "kind": "LinkedField",
                        "name": "tooManyPeriods",
                        "plural": false,
                        "selections": (v0 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "EmailAliasesConfig",
                        "kind": "LinkedField",
                        "name": "emailAliases",
                        "plural": false,
                        "selections": (v0 /*: any*/),
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Settings",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '795521b75e9d90915936d03cf0a10a18';
export default node;
