/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EmailDomainConfigContainer_formValues = {
    readonly protectedEmailDomains: ReadonlyArray<string>;
    readonly disposableEmailDomains: {
        readonly enabled: boolean;
    } | null;
    readonly " $refType": "EmailDomainConfigContainer_formValues";
};
export type EmailDomainConfigContainer_formValues$data = EmailDomainConfigContainer_formValues;
export type EmailDomainConfigContainer_formValues$key = {
    readonly " $data"?: EmailDomainConfigContainer_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"EmailDomainConfigContainer_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailDomainConfigContainer_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "protectedEmailDomains",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "DisposableEmailDomainConfiguration",
            "kind": "LinkedField",
            "name": "disposableEmailDomains",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "enabled",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '2f76d418276501c1f0e9229648e8c2ce';
export default node;
